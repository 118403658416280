// Overrides Bootstrap Sass variables
// See https://getbootstrap.com/docs/5.0/customize/sass/

$primary: #00196E;
$secondary: #FFAD1D;
$headings-color: $primary;
$font-family-base: "Montserrat";
$position-values: (
  n100: -100%,
  n90: -90%,
  n80: -80%,
  n70: -70%,
  n60: -60%,
  n50: -50%,
  n40: -40%,
  n30: -30%,
  n20: -20%,
  n10: -10%,
  0: 0,
  10: 10%,
  20: 20%,
  30: 30%,
  40: 40%,
  50: 50%,
  100: 100%
);

$border-radius: 1.2rem;

.card:hover{transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);}
.inner{overflow: hidden;}

.card-img-top {
  width: 100%;
  height: 13vw;
  object-fit: cover;
}

.col-sm{
  margin-bottom: 1.5em;
}