// To crop the avatar in a nice square
// Reference: https://stackoverflow.com/questions/15167545/how-to-crop-a-rectangular-image-into-a-square-with-css

.avatar {
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 100%;
}

.avatar-square-container {
  min-width: 100px;
  max-width: 160px;
  position: relative;
  width: 100%;
}

.avatar-square-container::after {
  content: "";
  display: block;
  padding-top: 100%;
}